.SortableList {
  position: relative;
  z-index: 0;
  background-color: #f3f3f3;
  border: 1px solid #efefef;
  border-radius: 3px;
  outline: none;
  width: 400px;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  border: 1px solid #e0e0e0;
  list-style: none;
  padding: 0;
}

.SortableItem {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  height: 60px;
  padding: 0 20px;
  background-color: #fff;
  border-bottom: 1px solid #efefef;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: #333;
  font-weight: 500;
}

.SortableHelper {
  box-shadow: 0 5px 5px -5px rgba(0, 0, 0, 0.2),
    0 -5px 5px -5px rgba(0, 0, 0, 0.2);
  background-color: rgba(255, 255, 255, 0.8);
  cursor: row-resize;
}

.payment-form {
  width: 100%;
  align-self: center;
  box-shadow: 0px 0px 0px 0.5px rgba(50, 50, 93, 0.1),
    0px 2px 5px 0px rgba(50, 50, 93, 0.1), 0px 1px 1.5px 0px rgba(0, 0, 0, 0.07);
  border-radius: 7px;
  padding: 40px;
}

.payment-input {
  border-radius: 6px;
  margin-bottom: 6px;
  padding: 12px;
  border: 1px solid rgba(50, 50, 93, 0.1);
  max-height: 44px;
  font-size: 16px;
  width: 100%;
  background: white;
  box-sizing: border-box;
}

.result-message {
  line-height: 22px;
  font-size: 16px;
}

.result-message a {
  color: rgb(89, 111, 214);
  font-weight: 600;
  text-decoration: none;
}

.payment-hidden {
  display: none;
}

#card-error {
  color: rgb(105, 115, 134);
  font-size: 16px;
  line-height: 20px;
  margin-top: 12px;
  text-align: center;
}

#card-element {
  border-radius: 4px 4px 0 0;
  padding: 12px;
  border: 1px solid rgba(50, 50, 93, 0.1);
  max-height: 44px;
  width: 100%;
  background: white;
  box-sizing: border-box;
}

#payment-request-button {
  margin-bottom: 32px;
}

/* Buttons and links */
.payment-button {
  background: #5469d4;
  font-family: Arial, sans-serif;
  color: #ffffff;
  border-radius: 0 0 4px 4px;
  border: 0;
  padding: 12px 16px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  display: block;
  transition: all 0.2s ease;
  box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
  width: 100%;
}

.payment-button:hover {
  filter: contrast(115%);
}

.payment-button:disabled {
  opacity: 0.5;
  cursor: default;
}

/* spinner/processing state, errors */
.spinner,
.spinner:before,
.spinner:after {
  border-radius: 50%;
}

.spinner {
  color: #ffffff;
  font-size: 22px;
  text-indent: -99999px;
  margin: 0px auto;
  position: relative;
  width: 20px;
  height: 20px;
  box-shadow: inset 0 0 0 2px;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}

.spinner:before,
.spinner:after {
  position: absolute;
  content: '';
}

.spinner:before {
  width: 10.4px;
  height: 20.4px;
  background: #5469d4;
  border-radius: 20.4px 0 0 20.4px;
  top: -0.2px;
  left: -0.2px;
  -webkit-transform-origin: 10.4px 10.2px;
  transform-origin: 10.4px 10.2px;
  -webkit-animation: loading 2s infinite ease 1.5s;
  animation: loading 2s infinite ease 1.5s;
}

.spinner:after {
  width: 10.4px;
  height: 10.2px;
  background: #5469d4;
  border-radius: 0 10.2px 10.2px 0;
  top: -0.1px;
  left: 10.2px;
  -webkit-transform-origin: 0px 10.2px;
  transform-origin: 0px 10.2px;
  -webkit-animation: loading 2s infinite ease;
  animation: loading 2s infinite ease;
}

@keyframes loading {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@media only screen and (max-width: 600px) {
  .payment-form {
    width: 100%;
  }
}

.masthead {
  height: 100vh;
  min-height: 500px;
  background-image: url('./images/pexels-mentatdgt-1311518.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
}

.layer {
  background-color: rgba(0, 0, 0, 0.15);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.masthead.content {
  height: 30vh;
  min-height: 200px;
}

.circle-tile {
  margin-bottom: 15px;
  text-align: center;
}
.circle-tile-heading {
  border: 3px solid rgba(255, 255, 255, 0.3);
  border-radius: 100%;
  color: #ffffff;
  height: 80px;
  margin: 0 auto -40px;
  position: relative;
  transition: all 0.3s ease-in-out 0s;
  width: 80px;
}
.circle-tile-heading .fa {
  line-height: 80px;
}
.circle-tile-content {
  padding-top: 50px;
}
.circle-tile-number {
  font-size: 26px;
  font-weight: 700;
  line-height: 1;
  padding: 5px 0 15px;
}
.circle-tile-description {
  text-transform: uppercase;
}
.circle-tile-footer {
  background-color: rgba(0, 0, 0, 0.1);
  color: rgba(255, 255, 255, 0.5);
  display: block;
  padding: 5px;
  transition: all 0.3s ease-in-out 0s;
}
.circle-tile-footer:hover {
  background-color: rgba(0, 0, 0, 0.2);
  color: rgba(255, 255, 255, 0.5);
  text-decoration: none;
}
.circle-tile-heading.dark-blue:hover {
  background-color: #2e4154;
}
.circle-tile-heading.green:hover {
  background-color: #138f77;
}
.circle-tile-heading.orange:hover {
  background-color: #da8c10;
}
.circle-tile-heading.blue:hover {
  background-color: #2473a6;
}
.circle-tile-heading.red:hover {
  background-color: #cf4435;
}
.circle-tile-heading.purple:hover {
  background-color: #7f3d9b;
}
.tile-img {
  text-shadow: 2px 2px 3px rgba(0, 0, 0, 0.9);
}

.dark-blue {
  background-color: #34495e;
}
.green {
  background-color: #16a085;
}
.blue {
  background-color: #2980b9;
}
.orange {
  background-color: #f39c12;
}
.red {
  background-color: #e74c3c;
}
.purple {
  background-color: #8e44ad;
}
.dark-gray {
  background-color: #7f8c8d;
}
.gray {
  background-color: #95a5a6;
}
.light-gray {
  background-color: #bdc3c7;
}
.yellow {
  background-color: #f1c40f;
}
.text-dark-blue {
  color: #34495e;
}
.text-green {
  color: #16a085;
}
.text-blue {
  color: #2980b9;
}
.text-orange {
  color: #f39c12;
}
.text-red {
  color: #e74c3c;
}
.text-purple {
  color: #8e44ad;
}
.text-faded {
  color: rgba(255, 255, 255, 0.7);
}

colgroup col {
  min-width: 65px !important;
}
